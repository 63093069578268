/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

$(".hamburger").click(function(){
  $(this).toggleClass("is-active");
  $(".nav-primary").animate({
      //width: "toggle",
      opacity: "toggle"
  }, 300);
});

$('.slider-default').slick({
  nextArrow: '<span class="btn btn-square slick-arrow arrow-right"><i class="fas fa-chevron-right"></i></span>',
  prevArrow: '<span class="btn btn-square slick-arrow arrow-left"><i class="fas fa-chevron-left"></i></span>',
  dots: true,
  speed: 600,
});

$('.slider-hero').slick({
  nextArrow: '<span class="btn btn-square slick-arrow arrow-right"><i class="fas fa-chevron-right"></i></span>',
  prevArrow: '<span class="btn btn-square slick-arrow arrow-left"><i class="fas fa-chevron-left"></i></span>',
  autoplay: true,
  autoplaySpeed: 7000,
  dots: false,
  speed: 600,
});

$('.slider-projecten').each(function( index ) {
  $(this).attr('projecten-slider', index);


  $('.slider', this).slick({
    nextArrow: '<span class="btn btn-square slick-arrow arrow-right"><i class="fas fa-chevron-right"></i></span>',
    prevArrow: '<span class="btn btn-square slick-arrow arrow-left"><i class="fas fa-chevron-left"></i></span>',
    speed: 600,
    slidesToShow: 2,
    slidesToScroll: 2,
    //appendArrows: '[projecten-slider="' + index + '"] .btn-list ul',
    dots: false,
    //mobileFirst: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  });
});

$('.slider-referenties').each(function( index ) {
  $(this).attr('referenties-slider', index);

  $('.slider', this).slick({
    speed: 600,
    appendDots: '[referenties-slider="' + index + '"] .slider-referenties-dots',
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 7000,
  });
});

function blokkenheight() {
   
  $('.section').each(function(){
  
    $('.featured-block-height',this).height('auto');

    if ($(window).width() > 767) {
      var highestBox = 0;
      $('.featured-block-height', this).each(function(){

          if($(this).height() > highestBox) {
             highestBox = $(this).height(); 
           }
      });  

      $('.featured-block-height',this).height(highestBox);
      $('.featured-same-height',this).outerHeight(highestBox);
    }


  });

  $('.section').each(function(){
  
    $('.news-block-height',this).height('auto');

    if ($(window).width() > 767) {
      var highestBox = 0;
      $('.news-block-height', this).each(function(){

          if($(this).height() > highestBox) {
             highestBox = $(this).height(); 
           }
      });  

      $('.news-block-height',this).height(highestBox);
    }


  });

}

blokkenheight();

$(window).resize(function() {
  blokkenheight();
});

/**
     * Retrieve posts
     */
    function get_posts($params) {

      $container = $('#container-async');
      $content   = $container.find('.content');
            $status    = $container.find('.status');

      $status.text('Loading posts ...');

      $.ajax({
                  url: bobz.ajax_url,
                  data: {
                    action: 'do_filter_posts',
          nonce: bobz.nonce,
          params: $params
                  },
                  type: 'post',
                  dataType: 'json',
                  success: function(data, textStatus, XMLHttpRequest) {
                
                    if (data.status === 200) {
                      $content.html(data.content);
                    }
                    else if (data.status === 201) {
                      $content.html(data.message);  
                    }
                    else {
                      $status.html(data.message);
                    }
               },
               error: function(MLHttpRequest, textStatus, errorThrown) {

          $status.html(textStatus);
          
          /*console.log(MLHttpRequest);
          console.log(textStatus);
          console.log(errorThrown);*/
               },
        complete: function(data, textStatus) {
          
          msg = textStatus;

                if (textStatus === 'success') {
                  msg = data.responseJSON.found;
                }

                $status.text('Posts found: ' + msg);
                
                /*console.log(data);
                console.log(textStatus);*/
              }
          });
    }

    /**
     * Bind get_posts to tag cloud and navigation
     */
    $('#container-async').on('click', 'a[data-filter], .pagination a', function(event) {
      if(event.preventDefault) { event.preventDefault(); }

      $this = $(this);

      /**
       * Set filter active
       */
      if ($this.data('filter')) {
        $this.closest('ul').find('.active').removeClass('active');
        $this.parent('li').addClass('active');
        $page = $this.data('page');
      }
      else {
        /**
         * Pagination
         */
        $page = parseInt($this.attr('href').replace(/\D/g,''));
        $this = $('.nav-filter .active a');
      }
      

          $params    = {
            'page' : $page,
            'tax'  : $this.data('filter'),
            'term' : $this.data('term'),
            'qty'  : $this.closest('#container-async').data('paged'),
          };

          // Run query
          get_posts($params);
    });
    
    $('a[data-term="all-terms"]').trigger('click');

$('.gallery-item').magnificPopup({
  type: 'image',
  gallery:{
    enabled:true
  }
});
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
